import { Link, graphql } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import * as Contentstyle from '../../components/content/content.module.scss'
import * as style from '../../components/album/album.module.scss'
import Content from '../../components/content/content'
import Img from "gatsby-image"
import React from 'react'

const Foresight = ( {data} ) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div className={style.albumDetails}>
        {modal ? (
          <Link to={closeTo}>
            <svg className={style.albumDetails__close} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.53553 0.464768C7.92606 0.855293 7.92606 1.48846 7.53553 1.87898L5.41421 4.0003L7.53553 6.12162C7.92606 6.51215 7.92606 7.14531 7.53553 7.53584C7.14501 7.92636 6.51184 7.92636 6.12132 7.53584L4 5.41452L1.87868 7.53584C1.48815 7.92636 0.854988 7.92636 0.464463 7.53584C0.0739387 7.14531 0.0739387 6.51215 0.464463 6.12162L2.58578 4.0003L0.464463 1.87898C0.073939 1.48846 0.0739387 0.855293 0.464463 0.464768C0.854988 0.0742437 1.48815 0.0742441 1.87868 0.464768L4 2.58609L6.12132 0.464768C6.51184 0.0742441 7.14501 0.0742437 7.53553 0.464768Z" />
            </svg>
          </Link>
        ) : (
          <div>
            {/* Not sure */}
          </div>
        )}

          <Content extraClass='content--modal'>
            <div className="column column--40">
              <div className="sticky">

            <Img className={style.albumDetails__cover} fluid={data.cover.childImageSharp.fluid} />

            <p className={style.albumDetails__credits}>
              <strong>Executive Producer:</strong> Specyal T<br/>
              <strong>Label:</strong> Independent<br/>
              <strong>Recorded At:</strong> Avenue Kode Studios<br/>
              <strong>Production Provided By:</strong> Mixtape Seoul, ZBeatz & Daniel Cruz<br/>
              <strong>Mixed & Mastered By:</strong> Simon Bader<br/>
            </p>
            
            <div className={Contentstyle.subtitleLowercase}>Tracks</div>

            <ol className={style.albumDetails__tracks}>
              <li>Double Take</li>
              <li>Lowkey</li>
              <li>All Day</li>
              <li>Last Kiss</li>
              <li>Date Nite</li>
              <li>All I Wanna Do</li>
              <li>One Thing</li>
              <li>Nite Vision</li>
              <li>Back In The Day</li>
              <li>Baecay</li>
              <li>Peace Of Mind</li>
            </ol>
              </div>

            </div>
            <div className="column column--60">
            <span className={Contentstyle.titleLowercase}>Track Synopsis</span>

            <p><strong>Double Take</strong><br/>
            A new season of fresh connections and opportunities after another long pandemic winter.</p>
            <hr />

            <p><strong>Lowkey</strong><br/>
            The immediate connection you sometimes feel when meeting someone for the first time.</p>
            <hr />

            <p><strong>All Day</strong><br/>
            Getting that rare opportunity of both you and that special someone in your life having a full day off with no plans.</p>
            <hr />

            <p><strong>Last Kiss</strong><br/>
            Craving that one last bit of intimacy from a love interest before they finally leave.</p>
            <hr />

            <p><strong>Date Nite</strong><br/>
            A couple dedicating the evening to themselves by spending a nite out on the town.</p>
            <hr />

            <p><strong>All I Wanna Do</strong><br/>
            A couple, who were prematurely separated on earth, is reunited in the afterlife in a tropical paradise after many years apart.</p>
            <hr />

            <p><strong>One Thing</strong><br/>
            Developing a strong connection with an alluring stranger all through a divider screen.</p>
            <hr />

            <p><strong>Nite Vision</strong><br/>
            Having an unexpected rendezvous with a complete stranger in the dark while traveling on a red eye overnight.</p>
            <hr />

            <p><strong>Back In The Day</strong><br/>
            Reminiscing about how different things use to be back in the day.</p>
            <hr />

            <p><strong>Baecay</strong><br/>
            Spending a romantic vacation with your significant other on a private island.</p>
            <hr />

            <p><strong>Peace Of Mind</strong><br/>
            Having that supportive partner and knowing they’ll always have your back no matter what life throws at you.</p>

           </div>

          </Content>

      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default Foresight


export const query = graphql`
query {
  cover: file(relativePath: {eq: "st-store/albums/foresight.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
}
`